<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">中断理由</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + 'form_reason'" class="form-label"
                >中断理由<span
                  v-if="formData.id"
                  class="badge text-bg-secondary ms-2 px-1"
                  >変更不可</span
                ><span v-else class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.reason"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': formErrors?.reason,
                  'bg-secondary': formData.id,
                }"
                :id="modalId + 'form_reason'"
                :readonly="formData.id"
              />
              <div v-if="formErrors?.reason" class="invalid-feedback">
                {{ formErrors.reason }}
              </div>
            </div>
            <div v-if="formData.id" class="col-12">
              <div class="form-check">
                <input
                  v-model="formData.is_active"
                  class="form-check-input"
                  type="checkbox"
                  :id="modalId + '_form_is_active'"
                />
                <label
                  :for="modalId + '_form_is_active'"
                  class="form-check-label"
                >
                  有効
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'InspectionReasonInterruptedForm',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    postOrPatch() {
      return store.dispatch(
        'inspection_reason_interrupted/postOrPatch',
        this.formData
      )
    },
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        await this.postOrPatch(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit', this.formData)
        await this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
