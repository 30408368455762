<template>
  <div class="flex-grow">
    <canvas ref="chartCanvas" class="w-full h-full"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { onMounted, ref } from 'vue'

Chart.register(...registerables)

export default {
  name: 'DataGraph',

  props: {
    datasetLabel: {
      type: String,
      default: '数',
    },
  },

  setup(props) {
    const chartInstance = ref(null)
    const chartCanvas = ref(null)

    // 各キー（Y軸のラベル）の高さを固定
    const ROW_HEIGHT = 30 // 1つの行の高さ（px）
    const BAR_THICKNESS = ROW_HEIGHT - 10 // 各データの高さ
    const LAYOUT_PADDING = 60 // 上下の余白

    // データの数に応じてグラフの高さを決定
    const calculateHeight = (dataLength) => {
      return dataLength * ROW_HEIGHT + LAYOUT_PADDING // データ数 × 行の高さ + 上下余白
    }

    const createChart = (labels, data) => {
      if (!chartCanvas.value) return

      // キャンバスの高さをデータ数に応じて設定
      chartCanvas.value.height = calculateHeight(labels.length)

      const ctx = chartCanvas.value.getContext('2d')
      chartInstance.value = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: props.datasetLabel,
              data: data,
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              barThickness: BAR_THICKNESS,
            },
          ],
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                padding: 10, // 左側の余白
              },
            },
            x: {
              beginAtZero: true,
              // data の count の最大値もしくは30に合わせて x 軸の最大値を設定
              max: Math.max(...data) > 30 ? Math.max(...data) : 30,
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      })
    }

    const updateChart = async (graphData) => {
      if (!graphData || Object.keys(graphData).length === 0) {
        return
      }

      try {
        const labels = Object.values(graphData).map((item) => item.name)
        const data = Object.values(graphData).map((item) => item.count)

        if (chartInstance.value) {
          chartInstance.value.destroy()
        }

        createChart(labels, data)
      } catch (error) {
        console.error('Error loading group counts:', error)
      }
    }

    onMounted(() => {
      updateChart()
    })

    return {
      updateChart,
      chartCanvas,
    }
  },
}
</script>
